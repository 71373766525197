<script setup>
import { ActionCancel, ActionDelete } from 'o365.vue.components.js';
import { h } from 'vue';

const props = defineProps({
    col: Object,
    colIndex: Number,
    activeEditCell: null,
    row: Object,
    disabled: Boolean,
});

function ODataGridCellRenderer(props) {
    return h(props.column.cellRenderSlot, {
        row: props.modelValue,
        'onUpdate:row': (value) => context.emit('update:modelValue', value),
        column: props.column,
        editModeOn: props.editModeOn,
    });
};
ODataGridCellRenderer.props = ['modelValue', 'column', 'editModeOn'];
ODataGridCellRenderer.emits = ['update:modelValue'];

</script>

<script>
import { OLink } from 'o365.vue.components.renderers.jsx';

    export default {
        name: 'ODataGridBodyCell',
        components: {OLink}
    }
</script>

<template>
    <div class="o365-body-cell" tabindex="-1" :data-o365-colindex="colIndex" :o365-field="col.field"
        :class="col.cellClass"
        :style="[{'width': col.width + 'px'},{'left': col.left + 'px'}, {'user-select': col.editable ? 'none':''}, ...col.cellStyles]">

        <template v-if="col.field === 'o365_Action'">
            <action-delete v-show="row.item._current && !row.item._isDirty && !row.item._isError" :data="row.item"><i class="fas fa-times"></i></action-delete>
            <action-cancel v-show="row.item._current && row.item._isDirty && !row.item._isError" :data="row.item"><i class="fas fa-undo-alt"></i></action-cancel>
        </template>

        <template v-else-if="col._isGroupColumn">
            <div v-if="row.item._isGroup" class="o365-group">
                <span class="o365-group-expand me-1">
                    <i class="bi bi-caret-down-fill"></i>
                </span>
                <span>{{$format(row.item[col.field], col) ?? $t('(null)') }}</span>
            </div>
            <div v-else="row._isGroup" class="o365-group">
                <span class="ms-4">{{$format(row.item[col.field], col)}}</span>
            </div>
        </template>

         <div v-else v-show="activeEditCell!==colIndex+'-'+row.item._key" class="text-truncate">

            <o-data-grid-cell-renderer
                v-if="col.cellRenderSlot"
                :column="col"
                v-model="row.item">
            </o-data-grid-cell-renderer>

            <template v-else-if="col.cellRenderer">
                <template v-if="col.cellRendererIsFunction">
                    <div v-html="col.cellRenderer(row)"></div>
                </template>
                <component v-else :is="col.cellRenderer" 
                    v-model="row.item" 
                    :column="col"
                    :disabled="disabled"
                    :editModeOn="activeEditCell === colIndex+'-'+row.item._key"
                    :instance="$root.$data">
                </component>
            </template>

            <template v-else>{{$format(row.item[col.field], col)}}</template>
        </div>
    </div>
</template>




